import { createTheme } from '@mui/material/styles';
import { DARK_MODE } from './color_theme';

let is_dark_theme = false
try {
  is_dark_theme = window.matchMedia("(prefers-color-scheme: dark)").matches;
} catch(err) {
  is_dark_theme = false
}

is_dark_theme = false;

const light_theme = {
  palette: {
    type: 'light',
    background: {
      default: "#efefef"
    }
  }
};

const dark_theme = {
  palette: DARK_MODE
};

const theme = createTheme(is_dark_theme ? dark_theme : light_theme);
export default theme;

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//     error: {
//       main: red.A400,
//     },
//     background: {
//       default: '#fff',
//     },
//   },
// });
